<template>
  <div id="app">
    <vue-scroll :ops="ops">
      <router-view />
    </vue-scroll>
  </div>
</template>

<script>
import Global from "@/Basic/Global";
export default {
  name: "App",
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#DCDFE6",
        },
      },
    };
  },
};
</script>
<style>
.el-main{
  overflow: unset !important;
}
.blue_button{
  background: #2EA4EE !important;
  border-color: #2EA4EE !important;
  color: #fff !important;
  font-size: 12px;
}
.blue_button_plain{
  background: #ffffff !important;
  border-color: #2EA4EE !important;
  color: #2EA4EE !important;
  font-size: 12px;
}
._link{
  color: #2EA4EE !important;
  cursor: pointer !important;
  font-size: 12px;
}
</style>

