// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import ElementUI from "element-ui";
import "./assets/css/element-#EE652E/index.css";
import "./assets/css/common/common.scss";
import "./assets/font_ico/iconfont.css";
import "./assets/font_ico/iconfont.js";
import "element-ui/lib/theme-chalk/base.css";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import VueCookies from "vue-cookies";
import evnt from "./assets/js/request";
import vuescroll from "vuescroll";
import "vuescroll/dist/vuescroll.css";
import VueClipboard from "vue-clipboard2";
import global from "./Basic/Global.vue";
/* Vue.config.productionTip = false;
import "@/mock/";
import server from "./http";
Vue.use(server);
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "uU5Gjj4LWxn3WKc3I4L7Ae6wI4iWCE1H",
}); */
import { showLoading, hideLoading } from "../src/assets/js/loading";
import { Notification } from "element-ui";
import { resolvewin, dowfilebyBlob } from "./assets/js/index";
import preventReClick from "./assets/js/preventReClick";
import Print from "vue-print-nb";
Vue.use(Print); //注册
// 时间插件
import moment from "moment";
Vue.prototype.$moment = moment;
// 抽屉组件二次封装
import BaseDrawer from "@/Base/BaseDrawer/BaseDrawer.vue";
Vue.component("BaseDrawer", BaseDrawer);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.use(VueClipboard); //复制到剪切板
Vue.use(vuescroll);
Vue.use(ElementUI, {
  size: VueCookies.get("size") || "small", // set element-ui default size
});
Vue.prototype.$axios = axios;
Vue.prototype.$cookie = VueCookies; //cookie
Vue.prototype.evnt = evnt; //加载公共方法、请求
Vue.prototype.JSONParse = evnt.JSONParse; //重新反序列化数据
Vue.prototype.AxiosFunc = evnt.AxiosFunc; //axios请求拼接
Vue.prototype.IsNull = evnt.IsNull; //是否为空
Vue.prototype.resetForm = evnt.resetForm; //重置表单
Vue.prototype.numberFixed = evnt.numberFixed; //数据保留小数位操作
Vue.prototype.myReplace = evnt.myReplace; //替换
Vue.prototype.guid = evnt.guid; //生成唯一ID
Vue.prototype.dowfilebyBlob = dowfilebyBlob; //单文件下载
Vue.prototype.resolvewin = resolvewin; //页面跳转
import Qs from "qs";

/* eslint-disable no-new */
/**
 * 请求拦截器
 */
let showLoadType = "1";
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const token = $cookies.get("AccessToken");
    // token && (config.headers.Authorization = 'bearer ' + token);
    // return config;
    const token = $cookies.get("AccessToken");
    const langcode = $cookies.get("langcode");
    if (token != null) {
      config.headers.Authorization = "bearer " + token;
    }
    config.headers.language = "zh-CN";
    if (langcode != null && langcode != "" && langcode != undefined) {
      config.headers.language = langcode;
    }
    if (config.type == "formData" || config.method == "post") {
      if (config.headers.token != "true") {
        config.data = Qs.stringify(config.data);
      }
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
// axios 拦截处理响应
axios.interceptors.response.use(
  function(response) {
    if (response.data.ErrorCode === 401) {
      var blackurl = window.location.href;
      resolvewin(
        global.accounturl +
          "login_mailbox?blackurl=" +
          encodeURIComponent(blackurl) +
          "&&langcode=" +
          $cookies.get("langcode"),
        {},
        3
      );
      // location.href =
      //  ;
    } else if (response.data.ErrorCode === 402) {
      // MessageBox('权限不足，是否回到个人中心?', '提示', {
      //   confirmButtonText: '确定',
      //   type: 'warning'
      // }).then(() => {
      //   router.push('/usermaster/usercenterindex');
      // });
    }
    // 对响应数据做点什么
    return response;
  },
  function(error) {
    /* if (showLoadType === "2") {
      hideLoading1();
    } else if (showLoadType === "3") {
      hideLoading2();
    } else {
      hideLoading();
    } */
    // 对响应错误做点什么
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "登录已失效，请重新登录";
          break;
        case 402:
          error.message = "权限不足";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求失败，请联系管理员";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "当前操作系统异常，请联系管理员";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
      let errorData = {
        status: error.response.status,
        message: error.message,
        config: error.response.config,
      };
      Notification({
        title: "出错了",
        type: "error",
        message: errorData.message,
      });
      return Promise.reject(error);
    } else {
      Notification({
        title: "出错了",
        type: "error",
        message: error.message,
      });
    }
    return Promise.reject(error);
  }
);

new Vue({
  el: "#app",
  router,
  store,
  components: {
    App,
  },
  template: "<App/>",
});
