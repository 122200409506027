<template></template>
<script>
//线上配置
const apiurl = "https://api.magicargo.com"; //接口地址
const olddelurl1 = "https://www.12156.com/"; //老版本官网2.0
const delurl1 = "http://magicargo-oms.oss-cn-hangzhou.aliyuncs.com"; //线上文件域名
const weburl1 = "http://magicargo.com/#/"; //佳程官网地址
const weburl2 = "http://b.magicargo.com/#/"; // 个人中心
const businessCenterUrl =
  "http://b.magicargo.com/#/ordermaster/orderlist?businessCenter=2"; // 商家中心
const pxCenterUrl = "http://oms.magicargo.com/#/"; // 佳程-跨境电商商家中心
const supplierUrl = "http://supplier.magicargo.com/#/"; // 佳程-箱管中心
const accounturl1 = "http://auth.magicargo.com/#/"; // 验证中心官网地址
const businessid1 = "bf53bfec-413a-44c9-bbe8-42a50682f9c1"; // 佳程商家Id
//线上oss配置
let OSS = require("ali-oss");
let client1 = new OSS({
  region: "oss-cn-hangzhou",
  accessKeyId: "LTAI4G3NeRF3iddKMNVcAcge",
  accessKeySecret: "c2MUhMNXbI3tJwdSLemTIyxti4cgRs",
  bucket: "magicargo-oms",
});

//开发环境
/* const apiurl = "http://47.111.166.176:32000"; //接口地址
const olddelurl1 = "https://www.12156.com/"; //老版本官网2.0
const delurl1 = "http://magicargo-oms-test.oss-cn-hangzhou.aliyuncs.com"; //本地文件域名
const weburl1 = "http://magicargo.com/#/"; //佳程官网地址
//const weburl1 = "http://47.111.166.176:30001/#/"; //佳程官网地址
const weburl2 = "http://47.111.166.176:30000/#/"; // 个人中心
const businessCenterUrl =
  "http://47.111.166.176:30000/#/ordermaster/orderlist?businessCenter=2"; // 商家中心
const pxCenterUrl = "http://47.111.166.176:30010/#/"; // 佳程-跨境电商商家中心
const supplierUrl = "http://47.111.166.176:30006/#/"; // 佳程-箱管中心
const accounturl1 = "http://47.111.166.176:30002/#/"; // 验证中心官网地址
//const position_documents1 = "e4081107-2639-473a-936f-f2d6a8023b18"; //单证支持
const businessid1 = "70a25101-0093-4898-a32a-f3e248f8ee1a"; // 测试 佳程商家Id
//阿里云 oss 配置
let OSS = require("ali-oss");
let client1 = new OSS({
  region: "oss-cn-hangzhou",
  accessKeyId: "LTAI4G3NeRF3iddKMNVcAcge",
  accessKeySecret: "c2MUhMNXbI3tJwdSLemTIyxti4cgRs",
  bucket: "magicargo-oms-test",
}); */

/* API目录 */
const Defurl = apiurl + "/magic/";
const userApi = apiurl + "/magic/user";
const baseApi = apiurl + "/magic/base";
const ordercenterApi = apiurl + "/magic/ordercenter";
const suppliercenterApi = apiurl + "/magic/suppliercenter";
const websiteDefurl = apiurl + "/magic/oms/website_data";
const wmsApi = apiurl + "/magic/wms";

export default {
  olddelurl1,
  delurl1,
  weburl1,
  weburl2,
  accounturl1,
  //position_documents1,
  businessid1,
  client1,
  pxCenterUrl,
  businessCenterUrl,
  supplierUrl,

  Defurl,
  userApi,
  baseApi,
  ordercenterApi,
  suppliercenterApi,
  websiteDefurl,
  wmsApi,
};
</script>
 