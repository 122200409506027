<template></template>

<script>
import globalconfig from "./Globalconfig.vue";

const olddelurl = globalconfig.olddelurl1; // 老版本官网2.0
const delurl = globalconfig.delurl1; //文件域名
const weburl = globalconfig.weburl1; //佳程官网地址
const weburl2 = globalconfig.weburl2; // 个人中心
const businessCenterUrl = globalconfig.businessCenterUrl; //供应链商家中心
const pxCenterUrl = globalconfig.pxCenterUrl; //跨境电商商家中心
const supplierUrl = globalconfig.supplierUrl; //箱管中心
const accounturl = globalconfig.accounturl1; /* 验证中心官网地址 */
//const position_documents = globalconfig.position_documents1; // "e4081107-2639-473a-936f-f2d6a8023b18"; //单证支持
const businessid = globalconfig.businessid1; //"70a25101-0093-4898-a32a-f3e248f8ee1a"; //佳程商家Id
//阿里云 oss 配置
let client = globalconfig.client1;
//滚动条公共配置
const scrollconfig = {
  vuescroll: {},
  scrollPanel: {
    initialScrollY: true,
    initialScrollX: false,
    scrollingX: false,
    scrollingY: true,
    speed: 300,
    easing: undefined,
    verticalNativeBarPos: "right",
    maxHeight: undefined,
    maxWidth: undefined,
  },
  rail: {
    background: "#f1f1f1",
    opacity: 1,
    size: "10px",
    specifyBorderRadius: "0px",
    gutterOfEnds: null,
    gutterOfSide: "4px",
    keepShow: false,
  },

  bar: {
    keepShow: true,
    hoverStyle: true,
    size: "10px",
    onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
    background: "#c1c1c1",
  },
};

// 日期选择组件的配置
const pickerOptions = {
  shortcuts: [
    {
      text: "最近一周",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近一个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近三个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "今天",
      onClick(picker) {
        const end = new Date();
        // const start = new Date();
        // start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [end, end]);
      },
    },
  ],
};
// 月份选择组件的配置
const monthpickerOptions = {
  shortcuts: [
    {
      text: "本月",
      onClick(picker) {
        picker.$emit("pick", [new Date(), new Date()]);
      },
    },
    {
      text: "今年至今",
      onClick(picker) {
        const end = new Date();
        const start = new Date(new Date().getFullYear(), 0);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近六个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setMonth(start.getMonth() - 6);
        picker.$emit("pick", [start, end]);
      },
    },
  ],
};

const ResultStatus = [
  {
    label: "退回",
    value: "1",
  },
  {
    label: "已正常",
    value: "2",
  },
  {
    label: "暂扣",
    value: "3",
  },
  {
    label: "待处理",
    value: "4",
  },
];
const CheckinTimeType = [
  {
    label: "签入时间",
    value: "1",
  },
  {
    label: "签出时间",
    value: "2",
  },
];
const orderStatus = [
  //订单状态
  {
    label: "待签入",
    value: "1",
  },
  {
    label: "已签入",
    value: "2",
  },
  {
    label: "已签出",
    value: "3",
  },
  {
    label: "暂扣",
    value: "4",
  },
  {
    label: "待退回",
    value: "5",
  },
  {
    label: "退回签出",
    value: "6",
  },
  {
    label: "问题件",
    value: "7",
  },
  {
    label: "已装柜",
    value: "8",
  },
  {
    label: "已取消",
    value: "9",
  },
];
const ContainerStatus = [
  {
    label: "未装柜",
    value: "0",
  },
  {
    label: "装柜中",
    value: "1",
  },
  {
    label: "已装柜",
    value: "2",
  },
  {
    label: "已签出",
    value: "3",
  },
];
export default {
  olddelurl,
  delurl,
  weburl,
  weburl2,
  businessCenterUrl,
  pxCenterUrl,
  supplierUrl,
  accounturl,
  businessid,
  client,
  scrollconfig,
  pickerOptions,
  monthpickerOptions,
  ResultStatus,
  CheckinTimeType,
  orderStatus,
  ContainerStatus,
};
</script>