<template>
  <div class="base-drawer">
    <el-drawer
      :visible.sync="visible"
      :with-header="false"
      :size="size + 'px'"
      :close-on-press-escape="false"
      :wrapperClosable="false"
    >
      <!-- 按钮区 -->
      <el-row class="baseBtngroup">
        <el-col :span="22" class="baseBtnRow">
          <span v-if="title" v-html="title" class="drawerTitles"></span>
          <slot name="header"></slot>
        </el-col>
        <el-col :span="1" :offset="1">
          <div class="closeIcon">
            <el-button type="text">
              <i class="iconfont iconguanbi2" @click="clickCloseHandle"></i
            ></el-button>
          </div>
        </el-col>
      </el-row>
      <!-- 标题(v-html用于，有些标题上的箱号需要变颜色) -->
      <!-- <el-row v-if="title" class="drawerTitle" v-html="title"></el-row> -->
      <!-- 有些内容需要固定在滚动条之外 -->
      <slot name="fix"></slot>
      <!-- 主体 -->
      <vue-scroll :ops="scrollOps" :style="{ height: scrollerHeight }">
        <div class="drawerContent">
          <slot></slot>
        </div>
      </vue-scroll>
    </el-drawer>
  </div>
</template>

<script>
import index from "@/assets/js/Base/BaseDrawer/BaseDrawer.js";
export default index;
</script>

<style lang='scss' scoped>
@import "../../assets/style/Base/BaseDrawer/BaseDrawer.scss";
</style>