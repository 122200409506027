import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import { resolvewin } from "../assets/js/index";
import globalconfig from "@/Basic/Globalconfig.vue";
//多路由配置合并
const routerpush = require("./page_Warehouse").router;
/*   .router.concat(require("./ordermaster").router)
  .concat(require("./SupportCenter").router)
  .concat(require("./taskmanagement").router); */
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "OrderCenterMaster",
      redirect: "/OrderCenter/OrderList",
      component: (resolve) =>
        require([
          "@/page_Warehouse/PageSet/WarehouseCenter_Master.vue",
        ], resolve),
      children: [
        {
          path: "OrderList",
          name: "OrderList",
          component: (resolve) =>
            require(["@/page_Warehouse/OrderCenter/OrderList"], resolve),
        },
      ],
    },
  ].concat(routerpush), //数字合并
});
//通过路由器上状态判断是否需要登录，进行跳转页面登录
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    //如果未匹配到路由,跳到404页面
    resolvewin(globalconfig.weburl1 + "notexist", {}, 3);
  }
  if (to.path !== "/bindtrack" && to.path !== "/boxoperation") {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf("micromessenger") > -1) {
      // 是微信端
      return (window.location.href = "./static/noData/noData.html");
    }
  }
  if (
    to.query.langcode != "" &&
    to.query.langcode != undefined &&
    to.query.langcode != null
  ) {
    $cookies.set("langcode", to.query.langcode, 60 * 60 * 24 * 30);
    Vue.delete(to.query, "langcode");
  }
  if (
    to.query.AccessToken != "" &&
    to.query.AccessToken != undefined &&
    to.query.AccessToken != null
  ) {
    $cookies.set("AccessToken", to.query.AccessToken, 60 * 60 * 24 * 30);
    Vue.delete(to.query, "AccessToken");
    next({
      path: to.path,
      query: to.query,
      fullPath: "",
    });
    // to.query = {};
  }
  var langcodes = $cookies.get("langcode");
  if (langcodes != "zh-CN" && langcodes != "en" && langcodes != "ru") {
    $cookies.set("langcode", "zh-CN", 60 * 60 * 24 * 30);
  }
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (istoken) {
      next();
    } else {
      next({
        path: "/login", // 跳转到登录页面
        query: {
          redirect: to.fullPath,
        }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    next();
  }
});
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  console.log("targetPath", error);
  if (isChunkLoadFailed) {
    router.go(0);
  }
});
export default router;
