import Vue from "vue";
import JSZip from "jszip";
import FileSaver from "file-saver";
import store from "../../store/store";
import router from "../../router";
import { delurl1 } from "./indexconfig.js";
import {
  showLoading,
  hideLoading,
  showLoading1,
  hideLoading1,
} from "../../assets/js/loading";

function getCookie(name) {
  var prefix = name + "=";
  var start = document.cookie.indexOf(prefix);

  if (start == -1) {
    return null;
  }

  var end = document.cookie.indexOf(";", start + prefix.length);
  if (end == -1) {
    end = document.cookie.length;
  }

  var value = document.cookie.substring(start + prefix.length, end);
  return unescape(value);
}

function fileNameFn(url) {
  getCookie("UserId");
  return `${url}${getCookie("UserId")}/`;
}
// imgExt: new Array(".png", ".jpg", ".jpeg", ".bmp", ".gif"),
// docExt: new Array(".doc", ".docx"),
// xlsExt: new Array(".xls", ".xlsx"),
// rarExt: new Array(".rar", ".zip"),
// const fileUrl = require("@/assets/images/Folder_54_fe544cc.png"),
// const excelUrl = require("@/assets/images/excel.png"),
// textUrl = require("@/assets/images/Text_54_6d41969.png"),
// videoUrl = require("@/assets/images/Video_54_7880c8d.png"),
// wordUrl = require("@/assets/images/Word_54_6167687.png"), // docExt
// zipUrl =  require("@/assets/images/ZIP_54_667d9c9.png"),
// otherUrl = require("@/assets/images/OtherN.png"),
// pdfUrl = require("@/assets/images/PDF_54_ae4e37a.png"),
//文件对应缩略图数组
const fileType = [
  {
    name: ".doc",
    url: require("@/assets/images/Word_54_6167687.png"),
  },
  {
    name: ".docx",
    url: require("@/assets/images/Word_54_6167687.png"),
  },
  {
    name: ".xls",
    url: require("@/assets/images/excel.png"),
  },
  {
    name: ".xlsx",
    url: require("@/assets/images/excel.png"),
  },
  {
    name: ".xlsm",
    url: require("@/assets/images/excel.png"),
  },
  {
    name: ".rar",
    url: require("@/assets/images/ZIP_54_667d9c9.png"),
  },
  {
    name: ".zip",
    url: require("@/assets/images/ZIP_54_667d9c9.png"),
  },
  {
    name: ".txt",
    url: require("@/assets/images/Text_54_6d41969.png"),
  },
  {
    name: ".text",
    url: require("@/assets/images/Text_54_6d41969.png"),
  },
  {
    name: ".pdf",
    url: require("@/assets/images/PDF_54_ae4e37a.png"),
  },
  {
    name: ".xps",
    url: "img",
  },
  {
    name: ".png",
    url: "img",
  },
  {
    name: ".jpg",
    url: "img",
  },
  {
    name: ".jpeg",
    url: "img",
  },
  {
    name: ".bmp",
    url: "img",
  },
  {
    name: ".gif",
    url: "img",
  },
];

function fileTypeFn(data) {
  let src = "";
  fileType.forEach((item) => {
    if (getType(data) == item.name) {
      src = item.url;
    }
  });
  return src;
}
//获取文件后缀名
function getType(file) {
  var filename = file;
  var index1 = filename.lastIndexOf(".");
  var index2 = filename.length;
  var type = filename.substring(index1, index2).toLowerCase();
  return type;
}

//获取文件名
function getName(url) {
  var obj = url.lastIndexOf("/");
  var filename = url.substr(obj + 1);
  return filename;
}

function isnull(valinfo) {
  if (
    valinfo === "" ||
    valinfo === null ||
    typeof valinfo === "undefined" ||
    valinfo === "null"
  ) {
    return true;
  } else {
    return false;
  }
}

function isNumber(val) {
  var regPos = /^\d+(\.\d+)?$/; //非负浮点数
  var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  if (regPos.test(val) || regNeg.test(val)) {
    return true;
  } else {
    return false;
  }
}

const delurl = delurl1; //本地文件域名
/**文件打包
 * arrImages:文件list:[{fileUrl:文件url,renameFileName:文件名}]
 * filename 压缩包名
 * */
function filesToRar(arrImages, filename) {
  let _this = this;
  let zip = new JSZip();
  let cache = {};
  let promises = [];
  //   _this.title = "正在加载压缩文件";
  showLoading1();
  for (let item of arrImages) {
    if (item.fileUrl === "" || item.fileUrl === delurl) {
      // 下载文件, 并存成ArrayBuffer对象(blob)
      const promise = zip.file(item.renameFileName, new Blob(), {
        binary: true,
      }); // 逐个添加文件
      cache[item.renameFileName] = "";
      promises.push(promise);
    } else {
      const promise = getImgArrayBuffer(item.fileUrl).then((data) => {
        // 下载文件, 并存成ArrayBuffer对象(blob)
        zip.file(item.renameFileName, data, {
          binary: true,
        }); // 逐个添加文件
        cache[item.renameFileName] = data;
      });
      promises.push(promise);
    }
  }

  Promise.all(promises)
    .then(() => {
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          // _this.title = "正在压缩";
          // 生成二进制流
          FileSaver.saveAs(content, filename); // 利用file-saver保存文件  自定义文件名
          // _this.title = "压缩完成";
          hideLoading1();
        });
    })
    .catch((res) => {
      hideLoading1();
      _this.$message.error("文件压缩失败");
    });
}
//获取文件blob
function getImgArrayBuffer(url) {
  let _this = this;
  return new Promise((resolve, reject) => {
    //通过请求获取文件blob格式
    let xmlhttp = new XMLHttpRequest();
    url = url.replace(delurl, "");
    if (!url.match(/^http[s]{0,1}:\/\//g)) {
      var vg = url.match(/^\//g);
      if (!vg) {
        url = "/" + encodeURIComponent(url);
      } else {
        url = "/" + encodeURIComponent(url.substr(1, url.length - 1));
      }
      url = "/delurl/" + url;
    }
    xmlhttp.open("GET", url + "?v=" + rand(100000, 999999), true);
    xmlhttp.responseType = "blob";
    xmlhttp.onload = function() {
      if (this.status == 200) {
        resolve(this.response);
      } else {
        reject(this.status);
      }
    };
    xmlhttp.send();
  });
}

function blackYDdatainfo(datas) {
  if (!isnull(datas)) {
    if (!isnull(datas.v)) {
      return datas.v + "".trim();
    }
  }
  return "";
}

function extend() {
  var length = arguments.length;
  var target = arguments[0] || {};
  if (typeof target !== "object" && typeof target !== "function") {
    target = {};
  }
  var i = 1;
  if (length === 1) {
    target = this;
    i--;
  }
  for (i; i < length; i++) {
    var source = arguments[i];
    for (var key in source) {
      // 使用for in会遍历数组所有的可枚举属性，包括原型。
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
}
/** 校验权限有效性 **/
function ckeckJurisdiction(groupname, key) {
  var _this = this;
  if (isnull(groupname) || isnull(key)) {
    return false;
  }
  var JurisdictionList = store.state.Jurisdiction;
  var indexall = JurisdictionList.findIndex((res) => {
    return res.key === "ALL";
  });
  if (indexall >= 0) {
    return true;
  }
  var index1 = JurisdictionList.findIndex((res) => {
    return res.key === groupname;
  });
  if (index1 < 0) {
    return false;
  }
  var index2 = JurisdictionList[index1].vals.findIndex((res) => {
    return res === key;
  });

  if (index2 < 0) {
    return false;
    ``;
  } else {
    return true;
  }
  return false;
}
var extname = [
  ".gif",
  ".jpg",
  ".jpeg",
  ".png",
  ".bmp",
  ".rar",
  ".zip",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".xlsm",
  ".pdf",
  ".txt",
  ".docm",
  ".7z",
];
/**校验文件有效性**/
function checkfiletype(filename) {
  var thisext = getType(filename);
  var index = extname.indexOf(thisext);
  if (index >= 0) {
    return true;
  } else {
    return false;
  }
}
/**获取本月第一天**/
function getCurrentMonthFirst() {
  var date = new Date();
  date.setDate(1);
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  return date.getFullYear() + "-" + month + "-" + day;
}
/**获取本月最后一天**/
function getCurrentMonthLast(count) {
  var date = new Date();
  date.setTime(date.getTime() + 3600 * 1000 * 24 * 30 * count);
  var year = date.getFullYear();
  var month = date.getMonth();
  month = month < 10 ? "0" + month : month;
  var day = new Date(year, month, 0);
  return year + "-" + month + "-" + day.getDate();
}
/**校验箱号有效性**/
function checkBoxNo(datas) {
  var reg = /[A-Za-z]{4}[0-9]{7}?/g;
  return reg.test(datas);
}
/**解析箱号**/
function GetBoxlist(datas) {
  var reg = /[A-Za-z]{4}[0-9]{7}?/g;
  return datas.match(reg);
}
/**打开新浏览器页面**/
//type 1:当前页面跳转 2:当前站点新页面打开 3.跨域名跳转 4.路由使用name 跳转页面
function resolvewin(url, datas, type) {
  switch (type) {
    case 1: //1:当前页面跳转
      router.push({
        path: url,
        query: datas,
      });
      break;
    case 2: //2:当前站点新页面打开
      let routeData = router.resolve({
        path: url,
        query: datas,
      });
      window.open(routeData.href, "_blank");
      break;
    case 3: //3.跨域名跳转
      location.href = url;
      break;
    case 4: //4.路由使用name 跳转页面
      router.push({
        name: url,
        query: datas,
      });
      break;
    case 5: //2:当前站点新页面打开
      let routeData1 = router.resolve({
        name: url,
        query: datas,
      });
      window.open(routeData1.href, "_blank");
      break;
    case 6: //3.跨域名跳转
      window.open(url, "_blank");
      break;
    default:
      break;
  }
}
/**下载单个文件**/
function deffiledow(url) {
  window.open(url, "_blank");
}
//去除文件名中特殊字符串
function CheckFileName(filename) {
  var flieArr = filename.split(".");
  var suffix = flieArr[flieArr.length - 1];
  var datafilename = filename.replace("." + suffix, "");
  var aaa = datafilename;
  let reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\s]/g;
  aaa = aaa.replace(reg, "_");
  aaa = aaa.replace(" ", "");
  return aaa + "." + suffix;
}
/**单文件文件流下载**/
function dowfilebyBlob(url, filename) {
  if (isnull(filename)) {
    filename = getName(url);
  }
  getBlob(url).then((data) => {
    saveAs(data, filename);
  });
}

function getBlob(url) {
  var _this = this;
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    url = url.replace(delurl, "");
    var vg = url.match(/^\//g);
    if (!vg) {
      url = encodeURIComponent(url);
    } else {
      url = encodeURIComponent(url.substr(1, url.length - 1));
    }
    console.log(delurl + url, "urlurl");
    xhr.open("GET", delurl + "/" + url + "?v=" + rand(100000, 999999), true);
    //xhr.open("GET", "/delurl/" + url + "?v=" + rand(100000, 999999), true);
    console.log(url, "urlurl");
    xhr.responseType = "blob";
    xhr.onload = function() {
      if (this.status == 200) {
        resolve(this.response);
      } else {
        reject(this.status);
      }
    };
    xhr.send();
  });
}

function saveAs(blob, filename) {
  FileSaver.saveAs(blob, filename);
}
// 获取开始日期到结束日期的数组
function getYearMonthDay(startDay = -90, endDay = 0) {
  // 开始日期
  let startDate = Date.now() + startDay * 24 * 60 * 60 * 1000;
  let date1 = new Date(startDate);
  let y1 = date1.getFullYear();
  let m1 = date1.getMonth() + 1;
  let d1 = date1.getDate();
  m1 = m1 < 10 ? "0" + m1 : m1;
  d1 = d1 < 10 ? "0" + d1 : d1;
  // 结束日期
  let endDate = Date.now() + endDay * 24 * 60 * 60 * 1000;
  let date2 = new Date(endDate);
  let y2 = date2.getFullYear();
  let m2 = date2.getMonth() + 1;
  let d2 = date2.getDate();
  m2 = m2 < 10 ? "0" + m2 : m2;
  d2 = d2 < 10 ? "0" + d2 : d2;
  // 什么参数都不传，默认 三月前到当前月，的数组
  // 可传开始天数startDay，结束天数endDay，都是以当前时间为基准。
  return [y1 + "-" + m1 + "-" + d1, y2 + "-" + m2 + "-" + d2];
}
// 获取当前日期
function getNowDate(type = "-") {
  let date = new Date();
  let y = date.getFullYear();
  let m = date.getMonth() + 1; // 当前月
  let d = date.getDate();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  return y + type + m + type + d; // 返回 当前日期
}
// 获取指定月份的最后一天(date是年月,如2021-01)
function getmonthEndDate(date) {
  let dateArr = date.split("-");
  let nextMonthFirstDay = new Date(dateArr[0], dateArr[1], 1);
  let oneDay = 1000 * 60 * 60 * 24;
  let lastTime = new Date(nextMonthFirstDay - oneDay);
  let endMonth = parseInt(lastTime.getMonth() + 1);
  let endDay = lastTime.getDate();
  endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
  endDay = endDay < 10 ? "0" + endDay : endDay;
  return dateArr[0] + "-" + endMonth + "-" + endDay;
}
// 随机数
function rand(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
// 导出文件命名
function exportFileName(name, num) {
  return name + "(" + num + ")_" + getNowDate("") + "_" + rand(1000, 9999);
}
// 数组包含对象，根据某一字段排序
function sortKey(array, key, type) {
  return array.sort(function(a, b) {
    var x = a[key];
    var y = b[key];
    if (type === "reverse") {
      return x > y ? 1 : x < y ? -1 : -1; // 正序
    } else {
      // 不传type是倒序
      return x > y ? -1 : x < y ? 1 : -1; // 倒序
    }
  });
}
/**获取本月第一天**/
function getMonths(count) {
  var date = new Date();
  date.setDate(1);
  var month = parseInt(date.getMonth() + 1);
  month = month + count;
  if (month < 10) month = "0" + month;
  return date.getFullYear() + "-" + month;
}
/**设置值**/
function SetlocalStorage(name, data) {
  const UserId = $cookies.get("UserId");
  localStorage.setItem(UserId + name, data);
}
/**获取值**/
function GetlocalStorage(name) {
  const UserId = $cookies.get("UserId");
  return localStorage.getItem(UserId + name);
}

/*
 * 获取浏览器版本信息
 */
function getBrowerInfo() {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let browserType = "";
  let browserVersion = "";
  let isIE =
    userAgent.match(/msie/) != null || userAgent.match(/trident/) != null;
  // 浏览器类型-IE
  if (userAgent.match(/msie/) != null || userAgent.match(/trident/) != null) {
    browserType = "IE";
    browserVersion = getIeVersion();
  }
  // 欧朋
  else if (window.opera || userAgent.indexOf("opr") > 0) {
    browserType = "欧朋";
    browserVersion = getOperaVersion(userAgent);
  }
  // UC
  else if (userAgent.indexOf("ubrowser") > 0) {
    browserType = "UC";
    browserVersion = userAgent.match(/ubrowser\/([\d.]+)/)[1];
  }
  // 百度
  else if (userAgent.indexOf("bidubrowser") > 0) {
    browserType = "百度";
    browserVersion = userAgent.match(/bidubrowser\/([\d.]+)/)[1];
  }
  // 搜狗
  else if (userAgent.indexOf("metasr") > 0 || userAgent.indexOf("se 2.x") > 0) {
    browserType = "搜狗";
    // browserVersion =  userAgent.match(/metasr\/([\d.]+)/)[1]
  }
  // QQ
  else if (userAgent.indexOf("tencenttraveler") > 0) {
    browserType = "QQ";
    browserVersion = userAgent.match(/tencenttraveler\/([\d.]+)/)[1];
  }
  // QQ
  else if (userAgent.indexOf("qqbrowser") > 0) {
    browserType = "QQ";
    browserVersion = userAgent.match(/qqbrowser\/([\d.]+)/)[1];
  }
  // 遨游
  else if (userAgent.indexOf("maxthon") > 0) {
    browserType = "遨游";
    browserVersion = userAgent.match(/maxthon\/([\d.]+)/)[1];
  }
  // 火狐
  else if (userAgent.indexOf("firefox") > 0) {
    browserType = "火狐";
    browserVersion = userAgent.match(/firefox\/([\d.]+)/)[1];
  }
  // edge
  else if (userAgent.indexOf("edge") > 0) {
    browserType = "Edge";
    browserVersion = userAgent.match(/edge\/([\d.]+)/)[1];
  }
  // 谷歌/360
  /*   else if (userAgent.indexOf("chrome") > 0) {
    if (validate360("type", "application/x360mmplugin")) {
      browserType = "360";
      // browserVersion =  userAgent.match(/chrome\/([\d.]+)/)[1]
    } else {
      browserType = "谷歌";
      browserVersion = userAgent.match(/chrome\/([\d.]+)/)[1];
    }
  } */
  // 苹果
  else if (userAgent.indexOf("Safari") > -1) {
    browserType = "Safari";
    browserVersion = userAgent.match(/version\/([\d.]+)/)[1];
  }
  var datas = {
    browserType: browserType,
    browserVersion: browserVersion,
  };
  return datas;
}
/*
 * 获取IE浏览器版本
 */
function getIeVersion() {
  let IEMode = document.documentMode;
  let rMsie = /(msie\s|trident.*rv:)([\w.]+)/;
  let ma = window.navigator.userAgent.toLowerCase();
  let match = rMsie.exec(ma);
  try {
    return match[2];
  } catch (e) {
    return IEMode;
  }
}
/*
 * 获取oper浏览器版本
 */

function getOperaVersion(userAgent) {
  try {
    if (window.opera) {
      return userAgent.match(/opera.([\d.]+)/)[1];
    } else if (userAgent.indexOf("opr") > 0) {
      return userAgent.match(/opr\/([\d.]+)/)[1];
    }
  } catch (e) {
    return 0;
  }
}

function getomsfilesico(datas) {
  var fileclass = "iconfont";
  if (datas.FileShowStatus >= 2) {
    fileclass += " iconfont_true";
  }
  switch (datas.FileType) {
    case 1: //提柜单
      fileclass += " icontiguidan_huaban";
      // return fileclass;
      break;
    case 2: //付费代码
      fileclass += " iconbiaodanfufei";
      // return fileclass;
      break;
    case 3: //装箱照片
      fileclass += " iconzhuangxiangzhaopian_huaban";
      //return fileclass;
      break;
    case 4: //运单
      fileclass += " iconyundan";
      //  return fileclass;
      break;
    case 5: //报关单
      fileclass += " iconbaoguandan";
      //return fileclass;
      break;
    case 6: //清关运单
      fileclass += " iconicn_shenjifenpei";
      // return fileclass;
      break;
    case 7: //随车资料
      fileclass += " iconsuicheziliao-";
      //   return fileclass;
      break;
    case 8: //放舱单
      fileclass += " iconfangcang";
      //   return fileclass;
      break;
    case 9: //水运单
      fileclass += " iconshuiyundan-";
      //  return fileclass;
      break;
    case 10: //进站资料
      fileclass += " icontubiaozhizuomoban";
      //  return fileclass;
      break;
    case 11: //调偏照片
      fileclass += " iconzhuangxiangzhaopian_huaban";
      //  return fileclass;
      break;
    case 12: //加固资料
      fileclass += " iconjiaguziliao";
      //  return fileclass;
      break;
    case 13: //查验照片
      fileclass += " iconzhuangxiangzhaopian_huaban";
      //  return fileclass;
      break;
    case 14: //拼柜照片
      fileclass += " iconzhuangxiangzhaopian_huaban";
      // return fileclass;
      break;
    case 15: //过磅单
      fileclass += " iconziliao";
      // return fileclass;
      break;
    case 16: //运单扫描件
      fileclass += " iconsaomiaojian";
      // return fileclass;
      break;
    case 17: //服务附件
      fileclass += " iconfuwu";
      // return fileclass;
      break;
    case 18: //费用附件
      //  fileclass += " iconjiesuanshenpi";
      return fileclass;
      break;
    case 19: //提箱
      //    fileclass += " iconjiesuanshenpi";
      return fileclass;
      break;
    case 20: //落箱
      //    fileclass += " iconjiesuanshenpi";
      // return fileclass;
      break;
    case 21: //还箱凭证
      fileclass += " icondaihuanxiang-";
      // return fileclass;
      break;
    case 22: //境外口岸查验
      fileclass += " iconjiancha";
      //return fileclass;
      break;
    case 23: //国内海关查验
      fileclass += " iconhaiguanshuju";
      //   return fileclass;
      break;
    case 24: //目的地查验
      fileclass += " iconjiancha1";
      // return fileclass;
      break;
    case 25: //境外拒收
      fileclass += " iconjushou";
      break;
    case 1000: //进仓单
      fileclass += " iconjincangdan-copy";
      break;
    case 1001: //清关预审单
      fileclass += " iconhetongyushen";
      break;
    case 1002: //拼箱报关
      fileclass += " iconbaoguandan";
      break;
    case 1003: //入库信息
      fileclass += " iconjinchang";
      break;
    default:
      break;
  }
  return fileclass;
}

function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "y+": date.getFullYear().toString(), // 年
    "M+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "m+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

export {
  SetlocalStorage,
  GetlocalStorage,
  FileSaver,
  fileNameFn,
  getCookie,
  fileTypeFn,
  getType,
  getName,
  isnull,
  isNumber,
  filesToRar,
  blackYDdatainfo,
  extend,
  ckeckJurisdiction,
  checkfiletype,
  getCurrentMonthLast,
  getCurrentMonthFirst,
  checkBoxNo,
  resolvewin,
  deffiledow,
  CheckFileName,
  dowfilebyBlob,
  rand,
  getYearMonthDay,
  exportFileName,
  sortKey,
  getNowDate,
  getmonthEndDate,
  getMonths,
  GetBoxlist,
  getBrowerInfo,
  getomsfilesico,
  dateFormat,
};
