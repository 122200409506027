import globalconfig from "@/Basic/Global.vue";
export default {
  name: "BaseDrawer",
  props: {
    // 抽屉的显示隐藏
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ""
    },
    // 抽屉宽度
    size: {
      type: Number,
      default: 800
    }
  },
  data() {
    return {
      scrollOps: globalconfig.scrollconfig
    };
  },
  computed: {
    scrollerHeight: function() {
      return window.innerHeight - 100 + "px";
    }
  },
  methods: {
    clickCloseHandle() {
      // 触发一个关闭事件,可以用于关闭前的数据重置
      this.$emit("close");
      this.$emit("closeDrawer");
      // 关闭抽屉
      this.$emit("update:visible", false);
    }
  }
};
