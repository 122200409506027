import axios from "axios";
import Qs from "qs";

export default {
  deepObjectMergeAry(FirstOBJ, arryOBJ) {
    //数组 循环对象进行合并
    for (var key in arryOBJ) {
      FirstOBJ = this.deepObjectMerge(FirstOBJ, arryOBJ[key]);
    }
    return FirstOBJ;
  },
  deepObjectMerge(FirstOBJ, SecondOBJ) {
    // 深度合并对象
    for (var key in SecondOBJ) {
      FirstOBJ[key] =
        FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]"
          ? this.deepObjectMerge(FirstOBJ[key], SecondOBJ[key])
          : (FirstOBJ[key] = SecondOBJ[key]);
    }
    return FirstOBJ;
  },
  JSONParse(data) {
    //重新反序列化数据  同源数据（引用传递）
    let obj = JSON.stringify(data);
    let newObj = JSON.parse(obj);
    return newObj;
  },
  AxiosFunc(type, url, data, timeout) {
    //axios请求拼接
    timeout = timeout || 30000;
    type = type || "post";
    if (type == "post") {
      return axios.post("/UserServer/magic/" + url, data, { timeout: timeout });
    } else if (type == "put") {
      return axios.put("/UserServer/magic/" + url, Qs.stringify(data), {
        timeout: timeout
      });
    } else if (type == "del") {
      return axios.delete("/UserServer/magic/" + url, {
        params: data,
        timeout: timeout
      });
    } else {
      return axios.get("/UserServer/magic/" + url, {
        params: data,
        timeout: timeout
      });
    }
  },
  IsNull(valinfo) {
    if (valinfo != "" && valinfo != null && typeof valinfo != "undefined") {
      return false;
    }
    return true;
  },
  myReplace(f, e) {
    //把f替换成e
    var reg = new RegExp(f, "g"); //创建正则RegExp对象
    return this.replace(reg, e);
  },
  //// 表单重置
  resetForm(refName) {
    if (this.$refs[refName]) {
      this.$refs[refName].resetFields();
    }
  },
  //生成唯一ID
  guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  //数据保留小数位操作
  numberFixed(data, num) {
    if (!data) {
      return data;
    } else {
      return parseFloat(data).toFixed(num);
    }
  }
};
