import Vue from "vue";
import Vuex from "vuex";
import { setMaxListeners } from "node-notifier";
Vue.use(Vuex);

export default new Vuex.Store({
  //要设置的全局访问的state对象
  state: {
    BoxNo: "",
    BachNumber: "",
    OrderID: "",
    ordercenterfile: "",
    Jurisdiction: [],
  },
  mutations: {
    SetJurisdiction(state, data) {
      state.Jurisdiction = data;
    },
    SetBoxNo(state, BoxNo) {
      state.BoxNo = BoxNo;
    },
    SetBachNumber(state, BachNumber) {
      state.BachNumber = BachNumber;
    },
    SetOrderID(state, OrderID) {
      state.OrderID = OrderID;
    },
    //文件上传路径设置--集装箱管理
    SetOrdercenterfile(state, data) {
      var url = "/Wharehous_web/" + data.OrderID + "/" + data.fileType + "/";
      if (data.SubFolder) {
        url += data.SubFolder + "/";
      }
      state.ordercenterfile = url;
    },
    //仓储文件存储地址
    setWharehousFile(state, data) {
      state.ordercenterfile =
        "/Wharehous_web/" + new Date().getTime() + "/" + data.fileType + "/";
    },

    //文件上传路径设置--其它
    setcustomerurl(state, data) {
      state.ordercenterfile = "/Wharehous_web/" + data;
    },
  },
});
