module.exports = {
  router: [
    {
      path: "/OrderCenter", //订单管理
      name: "OrderCenter",
      component: (resolve) =>
        require([
          "@/page_Warehouse/PageSet/WarehouseCenter_Master.vue",
        ], resolve),
      children: [
        /* {
          path: "test", //测试页
          name: "test",
          component: (resolve) => require(["@/page_Warehouse/test/"], resolve),
        }, */
        {
          path: "OrderList", //订单管理-订单列表
          name: "OrderList",
          component: (resolve) =>
            require(["@/page_Warehouse/OrderCenter/OrderList"], resolve),
        },
        {
          path: "OtherOrderList", //订单管理-第三方订单列表
          name: "OtherOrderList",
          component: (resolve) =>
            require(["@/page_Warehouse/OrderCenter/OtherOrderList"], resolve),
        },
        {
          path: "Checkin", //订单管理-签入
          name: "Checkin",
          component: (resolve) =>
            require(["@/page_Warehouse/OrderCenter/Checkin/Checkin"], resolve),
        },
        {
          path: "WaybillManage", //订单管理-运单管理
          name: "WaybillManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/OrderCenter/WaybillManage/WaybillManage",
            ], resolve),
        },
      ],
    },
    {
      path: "/ProblemCenter", //仓储-问题件管理
      name: "ProblemCenter",
      component: (resolve) =>
        require([
          "@/page_Warehouse/PageSet/WarehouseCenter_Master.vue",
        ], resolve),
      children: [
        {
          path: "ProblemList", //仓储-订单列表
          name: "ProblemList",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/ProblemList/ProblemList",
            ], resolve),
        },
        {
          path: "ContainerManage", //仓储-整柜管理
          name: "ContainerManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/ContainerManage/ContainerManage",
            ], resolve),
        },
        {
          path: "ProblemCheckout", //仓储-问题件签出
          name: "ProblemCheckout",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/ProblemCheckout/ProblemCheckout",
            ], resolve),
        },
        {
          path: "Container", //仓储-装柜
          name: "Container",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/Container/Container",
            ], resolve),
        },
        {
          path: "Container", //仓储-装柜
          name: "Container",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/Container/Container",
            ], resolve),
        },
        {
          path: "ContainerCheckout", //仓储-装柜签出
          name: "ContainerCheckout",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/ContainerCheckout/ContainerCheckout",
            ], resolve),
        },
        {
          path: "InventoryRecords", //仓储-入库记录
          name: "InventoryRecords",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/InventoryRecords/InventoryRecords",
            ], resolve),
        },
        {
          path: "OutboundRecords", //仓储-出库记录
          name: "OutboundRecords",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/OutboundRecords/OutboundRecords",
            ], resolve),
        },
        {
          path: "ReturnList", //仓储-退件列表
          name: "ReturnList",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/ReturnList/ReturnList",
            ], resolve),
        },
        {
          path: "InventoryList", //仓储-库存列表
          name: "InventoryList",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/InventoryList/InventoryList",
            ], resolve),
        },
        {
          path: "WithWarehouseManage", //仓储-配仓管理
          name: "WithWarehouseManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/WithWarehouseManage/WithWarehouseManage",
            ], resolve),
        },
        {
          path: "GoodsShelvesManage", //仓储-货位货架
          name: "GoodsShelvesManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/ProblemCenter/GoodsShelvesManage/GoodsShelvesManage",
            ], resolve),
        },
      ],
    },
    {
      path: "/LogisticsManage", //物流管理
      name: "LogisticsManage",
      component: (resolve) =>
        require([
          "@/page_Warehouse/PageSet/WarehouseCenter_Master.vue",
        ], resolve),
      children: [
        {
          path: "ShippersManage", //物流管理-承运商管理
          name: "ShippersManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/LogisticsManage/ShippersManage/ShippersManage",
            ], resolve),
        },
        {
          path: "DistributorsManage", //物流管理-配送商管理
          name: "DistributorsManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/LogisticsManage/DistributorsManage/DistributorsManage",
            ], resolve),
        },
        {
          path: "TraceList", //物流管理-运踪管理
          name: "TraceList",
          component: (resolve) =>
            require([
              "@/page_Warehouse/LogisticsManage/TraceList/TraceList",
            ], resolve),
        },
      ],
    },
    {
      path: "/FinancialManage", //财务管理
      name: "FinancialManage",
      component: (resolve) =>
        require([
          "@/page_Warehouse/PageSet/WarehouseCenter_Master.vue",
        ], resolve),
      children: [
        {
          path: "BillManage", //财务管理-客户帐单管理
          name: "BillManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/FinancialManage/BillManage/BillManage",
            ], resolve),
        },
        {
          path: "ServiceManage", //财务管理-服务商帐单
          name: "ServiceManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/FinancialManage/ServiceManage/ServiceManage",
            ], resolve),
        },
        {
          path: "ReceiveList", //财务管理-应收列表
          name: "ReceiveList",
          component: (resolve) =>
            require([
              "@/page_Warehouse/FinancialManage/ReceiveList/ReceiveList",
            ], resolve),
        },
        {
          path: "PayableList", //财务管理-应付列表
          name: "PayableList",
          component: (resolve) =>
            require([
              "@/page_Warehouse/FinancialManage/PayableList/PayableList",
            ], resolve),
        },
        {
          path: "CreditManage", //财务管理-收款管理
          name: "CreditManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/FinancialManage/CreditManage/CreditManage",
            ], resolve),
        },
        {
          path: "PaymentManage", //财务管理-付款管理
          name: "PaymentManage",
          component: (resolve) =>
            require([
              "@/page_Warehouse/FinancialManage/PaymentManage/PaymentManage",
            ], resolve),
        },
      ],
    },
    {
      path: "/SystemCenter", //系统配置
      name: "SystemCenter",
      component: (resolve) =>
        require([
          "@/page_Warehouse/PageSet/WarehouseCenter_Master.vue",
        ], resolve),
      children: [
        {
          path: "Orgnization", //系统配置-组织机构
          name: "Orgnization",
          component: (resolve) =>
            require([
              "@/page_Warehouse/SystemCenter/Orgnization/Orgnization",
            ], resolve),
        },
        {
          path: "SetRules", //系统配置-规则设置
          name: "SetRules",
          component: (resolve) =>
            require([
              "@/page_Warehouse/SystemCenter/SetRules/SetRules",
            ], resolve),
        },
        {
          path: "SetFBA", //系统配置-FBA仓
          name: "SetFBA",
          component: (resolve) =>
            require(["@/page_Warehouse/SystemCenter/SetFBA/SetFBA"], resolve),
        },
        {
          path: "StationInfo", //系统配置-港站信息
          name: "StationInfo",
          component: (resolve) =>
            require([
              "@/page_Warehouse/SystemCenter/StationInfo/StationInfo",
            ], resolve),
        },
        {
          path: "CountryInfo", //系统配置-国家信息
          name: "CountryInfo",
          component: (resolve) =>
            require([
              "@/page_Warehouse/SystemCenter/CountryInfo/CountryInfo",
            ], resolve),
        },
        {
          path: "MerchantsInfo", //系统配置-商家信息
          name: "MerchantsInfo",
          component: (resolve) =>
            require([
              "@/page_Warehouse/SystemCenter/MerchantsInfo/MerchantsInfo",
            ], resolve),
        },
        {
          path: "GoodsInfo", //系统配置-商品管理
          name: "GoodsInfo",
          component: (resolve) =>
            require([
              "@/page_Warehouse/SystemCenter/GoodsInfo/GoodsInfo",
            ], resolve),
        },
        {
          path: "mock", //系统配置-MOCK演示
          name: "mock",
          component: (resolve) =>
            require(["@/page_Warehouse/SystemCenter/mock/"], resolve),
        },
      ],
    },
  ],
};
